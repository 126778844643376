
import { ImageCover } from '/features/buildingBlocks/Image'

import styles from './Employee.css'

export function Employee({ image, name, functionTitle, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      {image && <div className={styles.avatar}>
        <ImageCover aspectRatio={1 / 1} {...{ image }} />
      </div>}
      <div className={styles.names}>
        <div className={styles.name}>
          {name}
        </div>

        <div className={styles.functionTitle}>
          {functionTitle}
        </div>
      </div>
    </div>
  )
}
